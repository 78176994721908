<template>
  <h1>4.3 Mixtures, solids, other chromatography</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>IMF in mixtures</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/HgD6a4TVxLY" title="Types of mixtures | Intermolecular forces and properties | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>IMF trends</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/q1eVfq9xWPg" title="Intermolecular Forces Trends: Melting &amp; Boiling Point, Viscosity, Surface Tension, Vapor Pressure" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Thin-layer (TLC) and column chromatography</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/yig3QCfBTzc" title="Column Chromatography" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>