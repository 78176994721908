import { createRouter, createWebHistory } from 'vue-router'
import Homepage from '../views/Homepage.vue'
import QuickAccess from '../views/QuickAccess.vue'
import PracticeTests from '../views/PracticeTests.vue'
import FRQAnswers from '../views/FRQAnswers.vue'

import ChemU1L1 from '../views/ChemU1L1.vue'
import ChemU1L2 from '../views/ChemU1L2.vue'
import ChemU1L3 from '../views/ChemU1L3.vue'
import ChemU1L4 from '../views/ChemU1L4.vue'
import ChemU1L5 from '../views/ChemU1L5.vue'
import ChemU1L6 from '../views/ChemU1L6.vue'

import ChemU2L1 from '../views/ChemU2L1.vue'
import ChemU2L2 from '../views/ChemU2L2.vue'
import ChemU2L3 from '../views/ChemU2L3.vue'
import ChemU2L4 from '../views/ChemU2L4.vue'
import ChemU2L5 from '../views/ChemU2L5.vue'

import ChemU3L1 from '../views/ChemU3L1.vue'
import ChemU3L2 from '../views/ChemU3L2.vue'
import ChemU3L3 from '../views/ChemU3L3.vue'
import ChemU3L4 from '../views/ChemU3L4.vue'
import ChemU3L5 from '../views/ChemU3L5.vue'

import ChemU4L1 from '../views/ChemU4L1.vue'
import ChemU4L2 from '../views/ChemU4L2.vue'
import ChemU4L3 from '../views/ChemU4L3.vue'
import ChemU4L4 from '../views/ChemU4L4.vue'

import ChemU5L1 from '../views/ChemU5L1.vue'
import ChemU5L2 from '../views/ChemU5L2.vue'
import ChemU5L3 from '../views/ChemU5L3.vue'
import ChemU5L4 from '../views/ChemU5L4.vue'
import ChemU5L5 from '../views/ChemU5L5.vue'

import ChemU6L1 from '../views/ChemU6L1.vue'
import ChemU6L2 from '../views/ChemU6L2.vue'
import ChemU6L3 from '../views/ChemU6L3.vue'

import ChemU7L1 from '../views/ChemU7L1.vue'
import ChemU7L2 from '../views/ChemU7L2.vue'
import ChemU7L3 from '../views/ChemU7L3.vue'
import ChemU7L4 from '../views/ChemU7L4.vue'

import ChemU8L1 from '../views/ChemU8L1.vue'
import ChemU8L2 from '../views/ChemU8L2.vue'
import ChemU8L3 from '../views/ChemU8L3.vue'
import ChemU8L4 from '../views/ChemU8L4.vue'

import ChemU9L1 from '../views/ChemU9L1.vue'
import ChemU9L2 from '../views/ChemU9L2.vue'
import ChemU9L3 from '../views/ChemU9L3.vue'
import ChemU9L4 from '../views/ChemU9L4.vue'
import ChemU9L5 from '../views/ChemU9L5.vue'
import ChemU9L6 from '../views/ChemU9L6.vue'

import ChemU10L1 from '../views/ChemU10L1.vue'
import ChemU10L2 from '../views/ChemU10L2.vue'
import ChemU10L3 from '../views/ChemU10L3.vue'

import ChemU11L1 from '../views/ChemU11L1.vue'
import ChemU11L2 from '../views/ChemU11L2.vue'
import ChemU11L3 from '../views/ChemU11L3.vue'

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Homepage
  },
  {
    path: '/quick_access',
    name: 'quick_access',
    component: QuickAccess
  },
  {
    path: '/practice_tests',
    name: 'practice_tests',
    component: PracticeTests
  },
  {
    path: '/frq_answers',
    name: 'frq_answers',
    component: FRQAnswers
  },

  {
    path: '/chemU1L1',
    name: 'chemU1L1',
    component: ChemU1L1
  },
  {
    path: '/chemU1L2',
    name: 'chemU1L2',
    component: ChemU1L2
  },
  {
    path: '/chemU1L3',
    name: 'chemU1L3',
    component: ChemU1L3
  },
  {
    path: '/chemU1L4',
    name: 'chemU1L4',
    component: ChemU1L4
  },
  {
    path: '/chemU1L5',
    name: 'chemU1L5',
    component: ChemU1L5
  },
  {
    path: '/chemU1L6',
    name: 'chemU1L6',
    component: ChemU1L6
  },

  {
    path: '/chemU2L1',
    name: 'chemU2L1',
    component: ChemU2L1
  },
  {
    path: '/chemU2L2',
    name: 'chemU2L2',
    component: ChemU2L2
  },
  {
    path: '/chemU2L3',
    name: 'chemU2L3',
    component: ChemU2L3
  },
  {
    path: '/chemU2L4',
    name: 'chemU2L4',
    component: ChemU2L4
  },
  {
    path: '/chemU2L5',
    name: 'chemU2L5',
    component: ChemU2L5
  },

  {
    path: '/chemU3L1',
    name: 'chemU3L1',
    component: ChemU3L1
  },
  {
    path: '/chemU3L2',
    name: 'chemU3L2',
    component: ChemU3L2
  },
  {
    path: '/chemU3L3',
    name: 'chemU3L3',
    component: ChemU3L3
  },
  {
    path: '/chemU3L4',
    name: 'chemU3L4',
    component: ChemU3L4
  },
  {
    path: '/chemU3L5',
    name: 'chemU3L5',
    component: ChemU3L5
  },

  {
    path: '/chemU4L1',
    name: 'chemU4L1',
    component: ChemU4L1
  },
  {
    path: '/chemU4L2',
    name: 'chemU4L2',
    component: ChemU4L2
  },
  {
    path: '/chemU4L3',
    name: 'chemU4L3',
    component: ChemU4L3
  },
  {
    path: '/chemU4L4',
    name: 'chemU4L4',
    component: ChemU4L4
  },

  {
    path: '/chemU5L1',
    name: 'chemU5L1',
    component: ChemU5L1
  },
  {
    path: '/chemU5L2',
    name: 'chemU5L2',
    component: ChemU5L2
  },
  {
    path: '/chemU5L3',
    name: 'chemU5L3',
    component: ChemU5L3
  },
  {
    path: '/chemU5L4',
    name: 'chemU5L4',
    component: ChemU5L4
  },
  {
    path: '/chemU5L5',
    name: 'chemU5L5',
    component: ChemU5L5
  },

  {
    path: '/chemU6L1',
    name: 'chemU6L1',
    component: ChemU6L1
  },
  {
    path: '/chemU6L2',
    name: 'chemU6L2',
    component: ChemU6L2
  },
  {
    path: '/chemU6L3',
    name: 'chemU6L3',
    component: ChemU6L3
  },

  {
    path: '/chemU7L1',
    name: 'chemU7L1',
    component: ChemU7L1
  },
  {
    path: '/chemU7L2',
    name: 'chemU7L2',
    component: ChemU7L2
  },
  {
    path: '/chemU7L3',
    name: 'chemU7L3',
    component: ChemU7L3
  },
  {
    path: '/chemU7L4',
    name: 'chemU7L4',
    component: ChemU7L4
  },

  {
    path: '/chemU8L1',
    name: 'chemU8L1',
    component: ChemU8L1
  },
  {
    path: '/chemU8L2',
    name: 'chemU8L2',
    component: ChemU8L2
  },
  {
    path: '/chemU8L3',
    name: 'chemU8L3',
    component: ChemU8L3
  },
  {
    path: '/chemU8L4',
    name: 'chemU8L4',
    component: ChemU8L4
  },

  {
    path: '/chemU9L1',
    name: 'chemU9L1',
    component: ChemU9L1
  },
  {
    path: '/chemU9L2',
    name: 'chemU9L2',
    component: ChemU9L2
  },
  {
    path: '/chemU9L3',
    name: 'chemU9L3',
    component: ChemU9L3
  },
  {
    path: '/chemU9L4',
    name: 'chemU9L4',
    component: ChemU9L4
  },
  {
    path: '/chemU9L5',
    name: 'chemU9L5',
    component: ChemU9L5
  },
  {
    path: '/chemU9L6',
    name: 'chemU9L6',
    component: ChemU9L6
  },

  {
    path: '/chemU10L1',
    name: 'chemU10L1',
    component: ChemU10L1
  },
  {
    path: '/chemU10L2',
    name: 'chemU10L2',
    component: ChemU10L2
  },
  {
    path: '/chemU10L3',
    name: 'chemU10L3',
    component: ChemU10L3
  },

  {
    path: '/chemU11L1',
    name: 'chemU11L1',
    component: ChemU11L1
  },
  {
    path: '/chemU11L2',
    name: 'chemU11L2',
    component: ChemU11L2
  },
  {
    path: '/chemU11L3',
    name: 'chemU11L3',
    component: ChemU11L3
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
