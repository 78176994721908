<template>
  <h1>5.5 Activation energy, catalysis</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Activation energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/YacsIU97OFc" title="Activation Energy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/D0ZyjpAin_Y" title="Activation energy: Kickstarting chemical reactions - Vance Kite" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Catalysts and activation energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/csPIDLU56Ng" title="Catalysts and Activation Energy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>