<template>
  <div class="topbar">
    <div class="homelink">
      <router-link class="normal_text" to="/">Back to Homepage</router-link>
    </div>
  </div>
  <router-view/>
</template>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #ffffff;
  }

  body {
    background: #303030;
    margin-left: 25%;
    margin-right: 25%;
  }

  .topbar {
    display: flex;
    align-items: left;
    border-bottom: 2px solid rgb(255,255,255);
    position:fixed;
    left:0px;
    top:0px;
    width: 100vw;
    background-color:rgb(50,50,50);
  }

  .homelink {
    text-align: center;
    padding:10px;
    margin: 0px;
    font-size:18px;
  }

  .homelink:hover {
    color: #cccccc;
    cursor: pointer;
  }

  .normal_text{
    color: #ffffff;
    text-decoration:none;
  }

  h1{
    font-size:40px;
    margin-top:70px;
  }

  h2{
    font-size:32px;
    margin:0px;
    margin-bottom:0px;
  }
</style>
