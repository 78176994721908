<template>
  <h1>10.1 Solubility and K<sub>sp</sub></h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>What is K<sub>sp</sub>?</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/WjiXbemBXkE" title="Solubility Product Constant (Ksp)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Calculating solubility from K<sub>sp</sub> problem walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Ywvw32DaxUk" title="Worked example: Calculating solubility from Kₛₚ | Equilibrium | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <a class="content_link" href="https://chem.libretexts.org/Bookshelves/General_Chemistry/Map%3A_General_Chemistry_(Petrucci_et_al.)/18%3A_Solubility_and_Complex-Ion_Equilibria/18.2%3A_Relationship_Between_Solubility_and_Ksp" target="_blank">Solubility and K<sub>sp</sub> article</a><br>
    <iframe src="https://chem.libretexts.org/Bookshelves/General_Chemistry/Map%3A_General_Chemistry_(Petrucci_et_al.)/18%3A_Solubility_and_Complex-Ion_Equilibria/18.2%3A_Relationship_Between_Solubility_and_Ksp" title="Solubility and Ksp" width=100% height=700px></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>