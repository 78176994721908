<template>
  <h1>5.4 Reaction mechanisms, reaction profiles, pre-equilibrium</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Reaction mechanisms</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/fEXq_RvnYgI" title="Energy Diagrams, Catalysts, and Reaction Mechanisms" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Reaction profiles</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/afM1Iqj20yQ" title="GCSE Chemistry: Reaction Profiles" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Pre-equilibrium</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/4czm7qIbUjA" title="The pre-equilibrium approximation | Kinetics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Rate determining step</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/dUptLijzwdg" title="Rate Determining Step" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>