<template>
  <h1>Practice tests</h1>
  <h2>Multiple Choice practice problems (<a class="content_link" href="https://www.crackap.com/ap/chemistry/" target="_blank">pop out</a>)</h2><br>
  <iframe src="https://www.crackap.com/ap/chemistry/" title="AP Chemistry MCQs" width=100% height=700px></iframe>
  <br><br><br><br><br><br>

  <h2>Free Response practice problems (<a class="content_link" href="https://apcentral.collegeboard.org/courses/ap-chemistry/exam/past-exam-questions" target="_blank">pop out</a>)</h2>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>2022</span><br>
    <iframe src="https://secure-media.collegeboard.org/apc/ap22-frq-chemistry.pdf" title="2022 FRQ" width=100% height=700px></iframe><br><br>
    <span>2021</span><br>
    <iframe src="https://apcentral.collegeboard.org/media/pdf/ap21-frq-chemistry.pdf?course=ap-chemistry" title="2021 FRQ" width=100% height=700px></iframe><br><br>
    <span>2019</span><br>
    <iframe src="https://apcentral.collegeboard.org/media/pdf/ap19-frq-chemistry.pdf" title="2019 FRQ" width=100% height=700px></iframe><br><br>
    <span>2018</span><br>
    <iframe src="https://secure-media.collegeboard.org/apc/ap18-frq-chemistry.pdf" title="2018 FRQ" width=100% height=700px></iframe><br><br>
    <a class="content_link" href="https://apcentral.collegeboard.org/courses/ap-chemistry/exam/past-exam-questions" target="_blank">2017 and before</a>
    <br><br><br>
  </div>
</template>


<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>