<template>
  <h1>7.4 Hybridization, bonding orbitals</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Sigma and pi bonds</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/pT8nrBrTOm4" title="Sigma and Pi Bonds Explained, Basic Introduction, Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Hybridization</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/pdJeQUd2g_4" title="Hybridization of Atomic Orbitals - Sigma &amp; Pi Bonds - Sp Sp2 Sp3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Finding hybridization quickly</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_OZQbemzx_c" title="EASY Method to Find the Hybridization of an Atom | Chemistry |" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>