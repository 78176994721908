<template>
  <h1>10.2 Factors affecting solubility, common ion effect</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Factors affecting solubility</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/qL5-lcc_TfY" title="Factors that Affect Solubility" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Common ion effect</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/fI3U9T7LigY" title="The common-ion effect | Equilibrium | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/qawipem0LwA" title="The Common Ion Effect" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Problem walkthroughs - molar solubility to K<sub>sp</sub> and vice versa; common ion effect</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/kO-9OajoPZs" title="Ksp - Molar Solubility, Ice Tables, &amp; Common Ion Effect" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>