<template>
  <h1>11.2 Voltaic cells, cell potential</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Voltaic cells</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/9Xncz_mMc5g" title="Introduction to galvanic/voltaic cells | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/inXgVjz1BDQ" title="Galvanic (voltaic) cells | Applications of thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Cell potential problems walkthrough</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/UzkLP8segcs" title="Cell Potential Problems - Electrochemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>