<template>
  <h1>9.5 Buffers, Henderson-Hasselbalch equation</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>A buffer is a solution whose pH changes very little when a small amount of acid or base is added to it. Its pH does NOT change when it is diluted.</span><br><br>
    <span>Henderson-Hasselbalch equation:</span><br>
    <span>pH = pK<sub>a</sub> + log([A<sup>-</sup>]/[HA])</span><br>
    <div style="font-size:16px;">
      <span>pH -- pH of the buffer</span><br>
      <span>pK<sub>a</sub> -- the pK<sub>a</sub> of the acid used to make the buffer</span><br>
      <span>HA -- the concentration of the acid used to make the buffer</span><br>
      <span>A<sup>-</sup> -- the concentration of its conjugate base</span><br>
    </div><br>
    <span>Buffers overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/gjKmQ501sAg" title="Introduction to buffers | Water, acids, and bases | Biology | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Henderson-Hasselbalch equation overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/7QgtdYiWH50" title="Henderson–Hasselbalch equation | Acids and bases | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Henderson-Hasselbalch equation problems walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/7BU2yTYKm0c" title="Practice Problem: Henderson-Hasselbalch Equation Calculations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/fFIZ6ped6GA" title="Buffer Calculations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>