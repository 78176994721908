<template>
  <h1>9.4 Explanation of acid/base strength using structure, pK<sub>a</sub></h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>The stronger the acid, the weaker the conjugate base.</span><br>
    <span>The stronger the base, the weaker the conjugate acid.</span><br><br>

    <span>Acid/base strength is measured by pK<sub>a</sub> and pK<sub>b</sub>:</span><br>
    <span>Lower pK<sub>a</sub> = larger K<sub>a</sub> = stronger acid</span><br>
    <span>Lower pK<sub>b</sub> = larger K<sub>b</sub> = stronger base</span><br><br>

    <span>Properties of pK<sub>a</sub>, pK<sub>b</sub>, K<sub>a</sub>, and K<sub>b</sub> (these are definitions that always hold true):</span>
    <span>(pK<sub>a</sub> of acid) + (pK<sub>b</sub> of conjugate base) = 14</span><br>
    <span>(K<sub>a</sub> of acid) × (K<sub>b</sub> of conjugate base) = K<sub>w</sub> = 10<sup>-14</sup></span><br>

    <br><br>
    <span>How the structure of an acid affects its strength</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/v_94NsuBwDM" title="Factors affecting acid strength | Acids and bases | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>