<template>
  <h1>3.4 Z<sub>eff</sub>, periodic trends</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Z<sub>eff</sub> (effective nuclear charge)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/JDsMR1bHGO4" title="How To Calculate The Effective Nuclear Charge of an Electron" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Periodic table basics, properties of elements</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/hePb00CqvP0" title="The Periodic Table: Atomic Radius, Ionization Energy, and Electronegativity" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Periodic trends, Coulomb's Law</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/gi2Y-kbrjCw" title="Periodic trends and Coulomb&#39;s law | Atomic structure and properties | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>