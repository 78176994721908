<template>
  <h1>1.5 Gas stoichiometry</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Ideal gas law basics</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/BxUS1K7xu30" title="The Ideal Gas Law: Crash Course Chemistry #12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Gas stoichiometry problems and walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/TqLlfHBFY08" title="Ideal Gas Law Practice Problems" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>