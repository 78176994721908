<template>
  <h1>8.3 ∆G, Q, and K</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>∆G, ∆G°, Q, and K</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/CF6QirzIaY8" title="ΔG, ΔG°, Q, and K" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Thermodynamics vs. kinetics</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/ur7GBAQgiB0" title="Thermodynamics vs. kinetics | Applications of thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>