<template>
  <h1>3.5 Mass Spectrometry</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Video on mass spectrometry</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/hSirWciIvSg" title="Mass Spectrometry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <a class="content_link" href="https://www.broadinstitute.org/technology-areas/what-mass-spectrometry" target="_blank">Article on mass spectrometry</a>
    <br><br><br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>