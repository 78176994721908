<template>
  <h1>9.1 Intro to acids and bases</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Acids and bases overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/vt8fB3MFzLk" title="GCSE Chemistry - Acids and Bases  #34" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Og8mRiIATJw" title="Acids and Bases" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Definitions of acids and bases</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/EyBkPwsRY2E" title="Acid and Base Definitions | Arrhenius, Bronsted-Lowry, and Lewis" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>