<template>
  <h1>8.4 Coupled reactions</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Coupling reactions - using the energy released from a spontaneous reaction to drive a non-spontaneous reaction</span><br><br>
    <span>Coupled reactions</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/NnSJsYa1IK0" title="Coupled reactions | Applications of thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <a class="content_link" href="https://chem.libretexts.org/Bookshelves/General_Chemistry/Map%3A_General_Chemistry_(Petrucci_et_al.)/19%3A_Spontaneous_Change%3A_Entropy_and_Gibbs_Energy/19.8%3A_Coupled_Reactions" target="_blank">Coupled reactions article</a><br>
    <iframe src="https://chem.libretexts.org/Bookshelves/General_Chemistry/Map%3A_General_Chemistry_(Petrucci_et_al.)/19%3A_Spontaneous_Change%3A_Entropy_and_Gibbs_Energy/19.8%3A_Coupled_Reactions" title="Coupling reactions" width=100% height=700px></iframe><br><br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>