<template>
  <h1>Quick Access</h1>
  <h2>Exam format</h2>
  <div class="normal_text" style="margin-top:15px;">
    <span style="font-size:20px;">Section I: Multiple Choice</span>
    <br>
    <span style="font-size:18px;">60 Questions | 1 Hour 30 minutes | 50% of Exam Score</span>
    <br>
    <span style="font-size:14px;">Questions are either separate questions or question sets (a series of related questions).</span>
    <br><br>
    <span style="font-size:20px;">Section II: Free Response</span>
    <br>
    <span style="font-size:18px;">7 Questions | 1 Hour 45 Minutes | 50% of Exam Score</span>
    <br>
    <span style="font-size:14px;">There are 3 long-answer and 4 short-answer questions. Long-answer questions are worth 10 points each; short-answer questions are worth 4 points each.</span>
    <br><br>
    <span style="font-size:20px;">Calculator policy</span>
    <br>
    <span style="font-size:16px;">Approved scientific and graphing calculators are allowed on both parts of the exam (TI-84 is allowed).</span>
    <br><br><br>
  </div>
  <h2>Score calculator</h2>
  <br>
  <div class="normal_text" style="font-size:18px">
    <form>
					MCQ score: <input type="number" min="0" max="60" :value="mcq_score" @input="mcqInput">/60 <br>
					FRQ score: <input type="number" min="0" max="46" :value="frq_score" @input="frqInput">/46 <br>
    </form>
    <br>
    <span style="font-size:18px;">Total composite score: {{ calcscore() }}/100</span>
    <br>
    <span style="font-size:18px;">Predicted AP score: </span>
    <span style="font-size:40px;">{{ findapscore() }}</span>
    <br>
  </div>
  <br><br><br>
  <h2>Formula sheet</h2>
  <br>
  <iframe src="https://apcentral.collegeboard.org/media/pdf/chemistry-equations-sheet-2020.pdf" title="AP Chemistry Formula Sheet" width=100% height=750px></iframe>
  <br><br><br>
</template>

<script setup>
  import { ref } from 'vue'

  const mcq_score = ref(45)
  const frq_score = ref(32)
  var compscore = 72
  var apscore = 5

  function mcqInput(e) {
    mcq_score.value = e.target.value
    calcscore()
  }
  function frqInput(e) {
    frq_score.value = e.target.value
    calcscore()
  }
  
  function calcscore() {
    compscore = Math.max(0,Math.min(100,Math.round(50/60*parseInt(mcq_score.value)+50/46*parseInt(frq_score.value))));
    return compscore
  }
  
  function findapscore() {
    if(compscore>=72){
      apscore = 5;
    }
    else if(compscore>=58){
      apscore = 4;
    }
    else if(compscore>=42){
      apscore = 3;
    }
    else if(compscore>=27){
      apscore = 2;
    }
    else{
      apscore = 1;
    }
    return apscore
  }

</script>