<template>
  <h1>9.3 Percent ionization, weak acids and bases</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Percent ionization</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/6s7ZQBzAvV4" title="Percent Ionization" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Percent ionization problem walkthrough</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_jEe0Tkyn3w" title="Worked example: Finding the percent ionization of a weak acid | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Strong and weak acids/bases</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_gYBbzkqrmE" title="GCSE Chemistry - The pH Scale &amp; Strong vs Weak Acids (Higher Tier)  #35" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/HyKNyV0dfTI" title="Strong and weak acids/bases | Acids, bases, and salts | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>