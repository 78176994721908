<template>
  <h1>9.6 Titrations, indicators, neutralization reactions</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Titrations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/d1XTOsnNlgg" title="Acid–base titrations | Chemical reactions | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Indicators - a good indicator has a pK<sub>a</sub> at or near the pH of the equivalence point</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/xYQlvTblgCY" title="What Are Indicators &amp; How Do We Use Them? | Chemical Tests | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Neutralization reactions</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/n9wUdgxCLMQ" title="Neutralization Reactions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/UfTJrAPjeys" title="Acid Base Neutralization Reactions &amp; Net Ionic Equations - Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>