<template>
  <h1>10.3 Free energy of dissolution</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Free energy of dissolution</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/jEEqbBrJWRI" title="Free Energy of Dissolution" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>