<template>
  <h1>2.2 Enthalpy</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Introduction to enthalpy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/SV7U4yAXL5I" title="Enthalpy: Crash Course Chemistry #18" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Heat calculations: q = mc∆T</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/yhNHJ7WdT8A" title="Heat Capacity, Specific Heat, and Calorimetry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Reaction energy diagrams</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/3Mjx0cFMpE8" title="Potential Energy Diagrams - Chemistry - Catalyst, Endothermic &amp; Exothermic Reactions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>