<template>
  <h1>6.2 RICE method</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>RICE method: Reaction, Initial amount, Change, Equilibrium</span><br>
    <span>Can be used with mass or pressure, but don't mix them!</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/DEgWwGRorNs" title="RICE Tables" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Crash course: equilibrium calculations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/DP-vWN1yXrY" title="Equilibrium Equations: Crash Course Chemistry #29" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Lots of RICE method problem walkthroughs!</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/J4WJCYpTYj8" title="Chemical Equilibrium Constant K - Ice Tables - Kp and Kc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>