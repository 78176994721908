<template>
  <h1>1.4 Redox reactions</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Intro to redox reactions</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/dF5lB7gRtcA" title="Oxidation and Reduction Reactions - Basic Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/5rtJdjas-mY" title="Introduction to Oxidation Reduction (Redox) Reactions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Balancing redox reactions - practice and walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/YWl4KHsUxuw" title="How To Balance Redox Reactions - General Chemistry Practice Test / Exam Review" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>