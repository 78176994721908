<template>
  <h1>9.2 Autoionization of water, pH of acids and bases</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Autoionization of water</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/vt6WjF1O_08" title="Autoionization of water | Acids and bases | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>pH scale overview</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/ckbsHM2igT0" title="What Is The pH Scale | Acids, Bases &amp; Alkalis | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>What makes something acidic?</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/RnyB2qbQtHk" title="What Makes Something Acidic? | Acids, Bases &amp; Alkali&#39;s | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>What makes something basic?</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/zYGnq7-_L9w" title="What Makes Things Alkali? | Acids, Bases &amp; Alkali&#39;s | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>