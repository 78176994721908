<template>
  <h1>3.2 Quantum mechanics, atomic orbitals</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Quantum mechanics of atomic orbitals (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/yyXDKX-PT2Q" title="Atomic Orbital Tutorial: An introduction to the Quantum Mechanics of Atomic Orbitals" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Quantum numbers, atomic orbitals, electron configurations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Aoi4j8es4gQ" title="Quantum Numbers, Atomic Orbitals, and Electron Configurations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Quantum mechanics, the Schrödinger Equation (a little out of the scope of AP chem)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/O6g-7rUgrdg" title="Quantum Mechanics and the Schrödinger Equation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>