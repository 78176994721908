<template>
  <h1>8.2 Laws of Thermodynamics, Gibbs free energy</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Gibbs free energy is a quantity used to measure the maximum amount of work done in a thermodynamic system when the temperature and pressure are constant.</span><br>
    <span>It is the energy extracted (or put into) the reaction for the change of enthalpy minus the energy needed to overcome the entropy change.</span><br>
    <span>Thus ∆G = ∆H - T∆S.</span><br><br>
    <span>Laws of Thermodynamics, Entropy, and Gibbs Free Energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/8N1BxHgsoOw" title="The Laws of Thermodynamics, Entropy, and Gibbs Free Energy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Intro to Gibbs free energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_ikwCBn8wLE" title="Introduction to Gibbs free energy | Applications of thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Using Gibbs free energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/huKBuShAa1w" title="Using Gibbs Free Energy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>∆G = ∆H - T∆S practice problem</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/fICs5vjBuX0" title="Practice Problem: Calculating Free Energy Change" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>