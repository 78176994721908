<template>
  <h1>4.2 States of matter, properties due to IMF, paper chromatography</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>States of matter and IMF</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/pKvo0XWZtjo" title="States of matter | States of matter and intermolecular forces | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Properties of matter due to IMF - surface tension</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/ubLcR2un-Ao" title="Intermolecular Forces and Properties of Liquids" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_RTF0DAHBBM" title="Surface tension | States of matter and intermolecular forces | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Paper chromatography</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/TdJ57SQ6GAQ" title="GCSE Chemistry - Paper Chromatography #63" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>