<template>
  <h1>1.6 Stoichiometry overview</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Introduction (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Gle1bPAZsgg" title="Stoichiometry Made Easy: Stoichiometry Tutorial Part 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Stoichiometry with mass (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/BZuS3Agn4pI" title="Stoichiometry with Mass: Stoichiometry Tutorial Part 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Percent yield (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_xeqkSQb0Pg" title="Percent Yield Made Easy: Stoichiometry Tutorial Part 4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Limiting reactant (includes Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/OqlMFEuDews" title="Limiting Reagent Made Easy: Stoichiometry Tutorial Part 5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/nZOVR8EMwRU" title="Introduction to Limiting Reactant and Excess Reactant" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Stoichiometry tricks (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/-1xfnq8yGk8" title="Stoichiometry Tricks" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>