<template>
  <h1>6.3 Le Châtelier's Principle, Q (reaction quotient)</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Reaction quotients (Q)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/1GiZzCzmO5Q" title="Chemical Equilibria and Reaction Quotients" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <a class="content_link" href="https://chem.libretexts.org/Bookshelves/Physical_and_Theoretical_Chemistry_Textbook_Maps/Supplemental_Modules_(Physical_and_Theoretical_Chemistry)/Equilibria/Le_Chateliers_Principle" target="_blank">Le Châtelier's Principle</a>
    <span> - "any change is counteracted"</span><br>
    <span>• Adding reactants or products or changing pressure or temperature will shift equilibrium in the opposite direction, counteracting the change</span><br>
    <span>• View heat as a reactant or product; add it to the correct side</span><br>
    <span style="padding-left:20px">• Exothermic reactions: add heat to product side (produces heat)</span><br>
    <span style="padding-left:20px">• Endothermic reactions: add heat to reactant side (requires input of heat)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/7zuUV455zFs" title="Le Chatelier&#39;s Principle Part 1 | Reactions | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/XhQ02egUs5Y" title="Le Chatelier&#39;s Principle: Part 2  | Reactions | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>

