<template>
  <h1>5.3 Integrated rate laws, swamping, half-life</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>See the formula sheet (under kinetics) for the three integrated rate laws. Make sure you know which corresponds to 0th order, 1st order, and 2nd order!</span><br><br>
    <span>Integrated rate laws (start at 6:56)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/wYqQCojggyM" title="Kinetics: Initial Rates and Integrated Rate Laws" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Swamping and pseudo rate constants</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/db9F3N7RgVI" title="Kinetics   the pseudo rate constant" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Half-life</span><br>
    <span>• The time it takes for the amount of a reactant to reduce to half of its original value</span><br>
    <span>• For first-order reactions ONLY</span><br>
    <span>• Half-life = ln(2)/k = 0.693/k</span><br><br>
    <span>Half-life problems walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/WTQvfvoOF3g" title="Half Life Chemistry Problems - Nuclear Radioactive Decay Calculations Practice Examples" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>