<template>
  <h1>3.3 Electron configuration, energy levels</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Energy levels (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/qFxNti5OKAM" title="What are Energy Levels?  Why?  What is Quantum?  How do Electrons Make Light Photons?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Energy level diagrams (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/bLvaqbC3FUQ" title="How to Draw an Energy Level Diagram of an Atom in Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Energy level diagrams</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_vK5KPycEvA" title="Atomic Energy Levels | Quantum physics | Physics | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Electron configuration (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/yg76liXA9mI" title="Electron Configuration and the Periodic Table" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Electron configuration</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/C6afrc1QS6Y" title="Aufbau&#39;s Principle, Hund&#39;s Rule &amp; Pauli&#39;s Exclusion Principle - Electron Configuration - Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Photoelectron spectroscopy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/UaTWrOTh4qU" title="Introduction to photoelectron spectroscopy | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Energy level transition calculations (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/OXVnXvXq1NI" title="How to Calculate Energy Level Transitions for the Hydrogen Atom with Electron Excitation/Relaxation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>