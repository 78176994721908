<template>
  <h1>1.3 Solution stoichiometry</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Molarity calculations (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/KLjWa9cE2uk" title="Molarity Made Easy: How to Calculate Molarity and Make Solutions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Molarity and dilutions (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/IksjhxM_UXs" title="Dilution Chemistry: How to Calculate and Perform Molarity Dilutions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>More dilution problems</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/FPidlCmymVg" title="Dilution Problems, Chemistry, Molarity &amp; Concentration Examples, Formula &amp; Equations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Writing net ionic equations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/iOCEYIbJYTk" title="How To Write Net Ionic Equations In Chemistry - A Simple Method!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Introduction to acids and bases</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/i2x4foEuRcI" title="Acids and Bases and Salts - Introduction | Chemistry | Don&#39;t Memorise" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Neutralization reactions with net ionic equations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/UfTJrAPjeys" title="Acid Base Neutralization Reactions &amp; Net Ionic Equations - Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Titration problems and walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/HJvALCcKYAc" title="Acid Base Titration Problems, Basic Introduction, Calculations, Examples, Solution Stoichiometry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>