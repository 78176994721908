<template>
  <h1>2.5 Bond enthalpy</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Bond enthalpies</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/VxcqAIaO-cA" title="Bond enthalpies | Thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Bond enthalpy and enthalpy of reaction</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/xE1gdQZcR-o" title="Bond enthalpy and enthalpy of reaction | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Bond enthalpy practice problems and walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/0Tm6JMeYX5g" title="Bond Energy Calculations &amp; Enthalpy Change Problems, Basic Introduction, Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>More thermochemistry practice problems and walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/LsqKL3pBVMA" title="Thermochemistry Equations &amp; Formulas - Lecture Review &amp; Practice Problems" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>