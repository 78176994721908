<template>
  <h1>3.1 Photoelectric effect, atomic orbitals</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Waves and light</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/9K0NvueuSCE" title="What is Light?  How to Calculate the Frequency, Wavelength, and Energy of Photons?  The Color Wheel" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Wave particle duality - electrons can be both particles and waves</span><br>
    <span>Photoelectric effect</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/MFPKwu5vugg" title="Wave-Particle Duality and the Photoelectric Effect" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Atomic orbitals Crash Course</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/cPDptc0wUYI" title="Orbitals: Crash Course Chemistry #25" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>