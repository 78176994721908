<template>
  <h1>4.4 Phase changes, real gases</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>IMF in phase changes</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/08kGgrqaZXA" title="Intermolecular Forces and Boiling Points" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Real gases</span><br>
    <span>• The higher the temperature and the lower the pressure, the more ideal the gas</span><br>
    <span>• Lower temperature and higher pressure → higher effect of IMF → lower than expected volume and pressure</span><br><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/UABFOI1sb7A" title="Real gases: Deviations from ideal behavior | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/tlaKcBB_C9E" title="Real Gas and Ideal Gas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>