<template>
  <h1>2.1 Energy</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>First Law of Thermodynamics</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/NyOYW07-L5g" title="First Law of Thermodynamics, Basic Introduction - Internal Energy, Heat and Work - Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>