<template>
  <h1>7.1 Ionic bonding</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Ionic bonds overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/zpaHPXVR8WU" title="What are Ionic Bonds?  | Properties of Matter | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/6DtrrWA5nkE" title="GCSE Chemistry - What is Ionic Bonding? How Does Ionic Bonding Work? Ionic Bonds Explained #14" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>More detailed video comparing ionic and covalent bonds</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/S_k0kr2eZSQ" title="Introduction to Ionic Bonding and Covalent Bonding" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>