<template>
  <h1>11.3 Electrolysis, Faraday's Law, electromagnetic spectrum</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Electrolysis</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/ilNOpROacf0" title="GCSE Chemistry - Electrolysis Part 1 - Basics and Molten Compounds #40" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Faraday's Law</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/v5qQusbk-j0" title="Quantitative electrolysis | Applications of thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Electromagnetic spectrum</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/m4t7gTmBK3g" title="What is the Electromagnetic Spectrum?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/pj_ya0e20vE" title="What is Light? Maxwell and the Electromagnetic Spectrum" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>