<template>
  <h1 class="title_text">1.2 Moles, formulas, mixtures, hydrates</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Concept of the mole</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/4q2elWPfB6A" title="Concept of Mole - Part 1 | Atoms and Molecules | Don&#39;t Memorise" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Percent composition to empirical formula</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/JeSSucG-CVw" title="Empirical Formula &amp; Molecular Formula Determination From Percent Composition" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Hydrate calculations</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/gl_QAO5y9Ig" title="How To Find The Empirical Formula of a Hydrated Ionic Compound" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
  .title_text{
    font-size:38px;
  }
</style>