<template>
  <h1>5.2 Determining rate laws</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Finding the rate law</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/liGCU9gaLcM" title="How to Find the Rate Law and Rate Constant (k)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Determining rate law - problem walkthrough</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/lTNidJFIvXU" title="Practice Problem: Initial Rates and Rate Laws" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>