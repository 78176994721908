<template>
  <h1>2.4 Hess’ Law and ∆H of phase changes</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Hess' Law and heats of formation</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/2q2u5sj4V00" title="Hess&#39;s Law and Heats of Formation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Heat of fusion and heat of vaporization</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/oc0ypeDELb0" title="Phase Changes, Heats of Fusion and Vaporization, and Phase Diagrams" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Worked example of specific heat, heat of fusion and vaporization calculation</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/zz4KbvF_X-0" title="Specific heat, heat of fusion and vaporization example | Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>