<template>
  <h1>7.3 Molecular geometry, electronegativity, polarity</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Valence shell electron pair repulsion (VSEPR) theory: each electron domain (area of electrons, could be a single, double, or triple bond or a lone pair) in the valence shell repels the other electron domains as far away from it as possible - they spread out as much as possible around the atom.</span><br><br>
    <span>Lone pairs are a little closer to the nucleus so they repel other domains a little stronger, making the other domains closer together → bond angle is a little smaller than predicted.</span><br><br>
    <span>Here's a chart that summarizes all the shapes you need to know - but please don't memorize it! Think through how the electron domains repel each other.</span><br>
    <div>
      <img style="width: 100%; height: 100%;" src="@/assets/images/VSEPR_theory.jpeg"><img>
    </div>
    <br><br>
    <span>VSEPR and molecular geometry</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/wYqQCojggyM" title="Kinetics: Initial Rates and Integrated Rate Laws" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/_Cw0_cJzkSI" title="Molecular Geometry &amp; VSEPR Theory - Basic Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Electronegativity</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/mEszSvBMd7Q" title="Electronegativity" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/c9n2-gkVsaw" title="Electronegativity, Basic Introduction, Periodic Trends - Which Element Is More Electronegative?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Polarity - an uneven sharing of electrons - the more electronegative element pulls the electrons closer to itself, causing the bond to be polar (negatively charged on the more electronegative atom).</span><br>
    <span>If the polar bonds are arranged in a symmetrical pattern, they cancel out, making the overall atom nonpolar.</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Jp8ctiApCVk" title="Electronegativity and Bond Polarity" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/SiZXRScxbl0" title="Polar and NonPolar Molecules: How To Tell If a Molecule is Polar or Nonpolar" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Polarity practice problems walkthrough</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/ds5HXmBkO0Y" title="Bond Polarity, Electronegativity and Dipole Moment - Chemistry Practice Problems" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>