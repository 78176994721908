<template>
  <h1>FRQ answers</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>2022</span><br>
    <iframe src="https://apcentral.collegeboard.org/media/pdf/ap22-sg-chemistry.pdf" title="2022 FRQ answers" width=100% height=700px></iframe><br><br>
    <span>2021</span><br>
    <iframe src="https://apcentral.collegeboard.org/media/pdf/ap21-sg-chemistry.pdf?course=ap-chemistry" title="2021 FRQ answers" width=100% height=700px></iframe><br><br>
    <span>2019</span><br>
    <iframe src="https://apcentral.collegeboard.org/media/pdf/ap19-sg-chemistry_1.pdf" title="2019 FRQ answers" width=100% height=700px></iframe><br><br>
    <span>2018</span><br>
    <iframe src="https://secure-media.collegeboard.org/ap/pdf/ap18-sg-chemistry.pdf" title="2018 FRQ answers" width=100% height=700px></iframe><br><br>
    <a class="content_link" href="https://apcentral.collegeboard.org/courses/ap-chemistry/exam/past-exam-questions" target="_blank">2017 and before</a>
    <br><br><br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>