<template>
  <h1>1.1 Nomenclature</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Ketzbook nomenclature</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/xSBYpaRze3Y" title="How to Name Chemicals Made Easy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Covalent compounds</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/DejkvR4pvRw" title="Naming Covalent Molecular Compounds" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Ionic compounds</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/mKo72RnN37E" title="Naming Ionic Compounds" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Oxyanions</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/G9OtnjooYaM" title="Naming oxyanions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Acids</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/5Jb2u9ihfm4" title="Naming Acids Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>