<template>
  <div class="title">
    AP Chemistry
  </div>

  <div class="topbar">
    <div class="homelink">
      <router-link class="normal_text" to="/">AP Chemistry Student Resources</router-link>
    </div>
  </div>
  <router-view/>
  
  <div>
    <img class="large_logo" src="@/assets/images/ap_chem_logo.png"><img>
  </div>

  <router-link class="quick_access_button" to="/quick_access">
			<img class="button_icon" src="@/assets/images/quick_access_logo.png" />
			<div class="button_text_outline">
				<div>
					<span style="font-size:32px;line-height:1.6;"><b>Quick access</b></span>
					<br>
					<span style="font-size:14px;">Exam format</span>
					<br>
					<span style="font-size:14px;">Formula sheet</span>
					<br>
					<span style="font-size:14px;">Score calculator</span>
				</div>
			</div>
		</router-link>

		<router-link class="practice_test_button" to="/practice_tests">
			<img class="button_icon" src="@/assets/images/practice_test_logo.png" />
			<div class="button_text_outline">
				<div>
					<span style="font-size:32px;line-height:1.4;"><b>Practice tests</b></span>
					<br>
					<span style="font-size:14px;">Unofficial practice MCQs</span>
					<br>
					<span style="font-size:14px;">Official FRQs from College Board</span>
				</div>
			</div>
		</router-link>

		<router-link class="practice_test_answers_button" to="/frq_answers">
			<img class="button_icon" src="@/assets/images/practice_test_answers_logo.png" />
			<div class="button_text_outline">
				<div>
					<span style="font-size:32px;line-height:1.4;"><b>FRQ answers</b></span>
					<br>
					<span style="font-size:14px;">Answers for the official FRQs from College Board</span>
				</div>
			</div>
		</router-link>

    <div class="sidebar">
			<div class="sidebar_element" id="unit1" :style="`top:${calcheight(1,1)}px`">
				Unit 1: Chemical Foundations
			</div>
			<div class="sidebar_element" id="unit2" :style="`top:${calcheight(2,1)}px`">
				Unit 2: Thermochemistry
			</div>
			<div class="sidebar_element" id="unit3" :style="`top:${calcheight(3,1)}px`">
				Unit 3: Atoms and Periodicity
			</div>
			<div class="sidebar_element" id="unit4" :style="`top:${calcheight(4,1)}px`">
				Unit 4: Intermolecular Forces
			</div>
			<div class="sidebar_element" id="unit5" :style="`top:${calcheight(5,1)}px`">
				Unit 5: Kinetics
			</div>
			<div class="sidebar_element" id="unit6" :style="`top:${calcheight(6,1)}px`">
				Unit 6: Equilibrium
			</div>
			<div class="sidebar_element" id="unit7" :style="`top:${calcheight(7,1)}px`">
				Unit 7: Chemical Bonding
			</div>
			<div class="sidebar_element" id="unit8" :style="`top:${calcheight(8,1)}px`">
				Unit 8: Entropy and Thermodynamics
			</div>
			<div class="sidebar_element" id="unit9" :style="`top:${calcheight(9,1)}px`">
				Unit 9: Acids and Bases
			</div>
			<div class="sidebar_element" id="unit10" :style="`top:${calcheight(10,1)}px`">
				Unit 10: Solubility Equilibria
			</div>
			<div class="sidebar_element" id="unit11" :style="`top:${calcheight(11,1)}px`">
				Unit 11: Electrochemistry
			</div>
			<div class="sidebar_element" id="unit0" :style="`top:${calcheight(12,1)}px; visibility:hidden`">
				{{openclose(oldX,mousePosX,oldY,mousePosY)}}
			</div>
		</div>

    <!-- the : are v-bind directives that allow insertion of javascript code; the ` are for template literals -->
    <div class="sidebar_2" id="lessons1" :style="`visibility:hidden;top:${calcheight(1,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU1L1">1.1 Nomenclature</router-link>
      <router-link class="sidebar_2_element" to="/chemU1L2" :style="`top:${calcheight(1,2)}px`">1.2 Moles, formulas, mixtures, hydrates</router-link>
      <router-link class="sidebar_2_element" to="/chemU1L3" :style="`top:${calcheight(1,3)}px`">1.3 Solution stoichiometry</router-link>
      <router-link class="sidebar_2_element" to="/chemU1L4" :style="`top:${calcheight(1,4)}px`">1.4 Redox reactions</router-link>
      <router-link class="sidebar_2_element" to="/chemU1L5" :style="`top:${calcheight(1,5)}px`">1.5 Gas stoichiometry</router-link>
      <router-link class="sidebar_2_element" to="/chemU1L6" :style="`top:${calcheight(1,6)}px`">1.6 Stoichiometry overview</router-link>
		</div>
    <div class="sidebar_2" id="lessons2" :style="`visibility:hidden;top:${calcheight(2,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU2L1">2.1 Energy</router-link>
      <router-link class="sidebar_2_element" to="/chemU2L2" :style="`top:${calcheight(2,2)}px`">2.2 Enthalpy</router-link>
      <router-link class="sidebar_2_element" to="/chemU2L3" :style="`top:${calcheight(2,3)}px`">2.3 Enthalpy of reaction, Beer's Law</router-link>
      <router-link class="sidebar_2_element" to="/chemU2L4" :style="`top:${calcheight(2,4)}px`">2.4 Hess' Law, ∆H of phase changes</router-link>
      <router-link class="sidebar_2_element" to="/chemU2L5" :style="`top:${calcheight(2,5)}px`">2.5 Bond enthalpy</router-link>
		</div>
    <div class="sidebar_2" id="lessons3" :style="`visibility:hidden;top:${calcheight(3,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU3L1">3.1 Photoelectric effect, atomic orbitals</router-link>
      <router-link class="sidebar_2_element" to="/chemU3L2" :style="`top:${calcheight(3,2)}px`">3.2 Quantum mechanics, atomic orbitals</router-link>
      <router-link class="sidebar_2_element" to="/chemU3L3" :style="`top:${calcheight(3,3)}px`">3.3 Electron configuration, energy levels</router-link>
      <router-link class="sidebar_2_element" to="/chemU3L4" :style="`top:${calcheight(3,4)}px`">3.4 Z<sub>eff</sub>, periodic trends</router-link>
      <router-link class="sidebar_2_element" to="/chemU3L5" :style="`top:${calcheight(3,5)}px`">3.5 Mass Spectrometry</router-link>
		</div>
    <div class="sidebar_2" id="lessons4" :style="`visibility:hidden;top:${calcheight(4,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU4L1">4.1 Intermolecular forces</router-link>
      <router-link class="sidebar_2_element" to="/chemU4L2" :style="`top:${calcheight(4,2)}px`">4.2 States of matter, properties from IMF</router-link>
      <router-link class="sidebar_2_element" to="/chemU4L3" :style="`top:${calcheight(4,3)}px`">4.3 Mixtures, solids, chromatography</router-link>
      <router-link class="sidebar_2_element" to="/chemU4L4" :style="`top:${calcheight(4,4)}px`">4.4 Phase changes, real gases</router-link>
		</div>
    <div class="sidebar_2" id="lessons5" :style="`visibility:hidden;top:${calcheight(5,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU5L1">5.1 Reaction rate, collision theory</router-link>
      <router-link class="sidebar_2_element" to="/chemU5L2" :style="`top:${calcheight(5,2)}px`">5.2 Determining rate laws</router-link>
      <router-link class="sidebar_2_element" to="/chemU5L3" :style="`top:${calcheight(5,3)}px`">5.3 Integrated rate laws, swamping</router-link>
      <router-link class="sidebar_2_element" to="/chemU5L4" :style="`top:${calcheight(5,4)}px`">5.4 Reaction mechanisms/profiles</router-link>
      <router-link class="sidebar_2_element" to="/chemU5L5" :style="`top:${calcheight(5,5)}px`">5.5 Activation energy, catalysis</router-link>
		</div>
    <div class="sidebar_2" id="lessons6" :style="`visibility:hidden;top:${calcheight(6,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU6L1">6.1 Dynamic equilibrium</router-link>
      <router-link class="sidebar_2_element" to="/chemU6L2" :style="`top:${calcheight(6,2)}px`">6.2 RICE method</router-link>
      <router-link class="sidebar_2_element" to="/chemU6L3" :style="`top:${calcheight(6,3)}px`">6.3 Le Châtelier's Principle, Q</router-link>
		</div>
    <div class="sidebar_2" id="lessons7" :style="`visibility:hidden;top:${calcheight(7,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU7L1">7.1 Ionic bonding</router-link>
      <router-link class="sidebar_2_element" to="/chemU7L2" :style="`top:${calcheight(7,2)}px`">7.2 Lewis structures, covalent bonding</router-link>
      <router-link class="sidebar_2_element" to="/chemU7L3" :style="`top:${calcheight(7,3)}px`">7.3 Molecular shapes, electronegativity</router-link>
      <router-link class="sidebar_2_element" to="/chemU7L4" :style="`top:${calcheight(7,4)}px`">7.4 Hybridization, bonding orbitals</router-link>
		</div>
    <div class="sidebar_2" id="lessons8" :style="`visibility:hidden;top:${calcheight(8,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU8L1">8.1 Entropy</router-link>
      <router-link class="sidebar_2_element" to="/chemU8L2" :style="`top:${calcheight(8,2)}px`">8.2 Laws of Thermodynamics, ∆G</router-link>
      <router-link class="sidebar_2_element" to="/chemU8L3" :style="`top:${calcheight(8,3)}px`">8.3 ∆G, Q, and K</router-link>
      <router-link class="sidebar_2_element" to="/chemU8L4" :style="`top:${calcheight(8,4)}px`">8.4 Coupled reactions</router-link>
		</div>
    <div class="sidebar_2" id="lessons9" :style="`visibility:hidden;top:${calcheight(9,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU9L1">9.1 Intro to acids and bases</router-link>
      <router-link class="sidebar_2_element" to="/chemU9L2" :style="`top:${calcheight(9,2)}px`">9.2 Water autoionization, acid/base pH</router-link>
      <router-link class="sidebar_2_element" to="/chemU9L3" :style="`top:${calcheight(9,3)}px`">9.3 Percent ionization, weak acids/bases</router-link>
      <router-link class="sidebar_2_element" to="/chemU9L4" :style="`top:${calcheight(9,4)}px`">9.4 Acid/base strength/structure, pK<sub>a</sub></router-link>
      <router-link class="sidebar_2_element" to="/chemU9L5" :style="`top:${calcheight(9,5)}px`">9.5 Buffers, H-H equation</router-link>
      <router-link class="sidebar_2_element" to="/chemU9L6" :style="`top:${calcheight(9,6)}px`">9.6 Titrations, indicators, neutralization</router-link>
		</div>
    <div class="sidebar_2" id="lessons10" :style="`visibility:hidden;top:${calcheight(10,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU10L1">10.1 Solubility and K<sub>sp</sub></router-link>
      <router-link class="sidebar_2_element" to="/chemU10L2" :style="`top:${calcheight(10,2)}px`">10.2 Factors affecting solubility</router-link>
      <router-link class="sidebar_2_element" to="/chemU10L3" :style="`top:${calcheight(10,3)}px`">10.3 Free energy of dissolution</router-link>
		</div>
    <div class="sidebar_2" id="lessons11" :style="`visibility:hidden;top:${calcheight(11,1)}px`">
      <router-link class="sidebar_2_element" to="/chemU11L1">11.1 Redox reactions</router-link>
      <router-link class="sidebar_2_element" to="/chemU11L2" :style="`top:${calcheight(11,2)}px`">11.2 Voltaic cells, cell potential</router-link>
      <router-link class="sidebar_2_element" to="/chemU11L3" :style="`top:${calcheight(11,3)}px`">11.3 Electrolysis, Faraday's Law, EMS</router-link>
		</div>
</template>

<script>
  export default {
    name: 'HomepageComponent',
    components: {

    },
    data() {
      return {
        opened: 0,
        oldX: 0,
        oldY: 0,
        mousePosX: 0,
        mousePosY: 0,
      };
    },
    mounted() { //universal; don't call methods in here
      document.addEventListener("mousemove", (event) => {
        this.oldX=this.mousePosX;
        this.oldY=this.mousePosY;
        this.mousePosX = event.clientX;
        this.mousePosY = event.clientY;
      });
    },
    methods: {
      openclose: function(oldX,newX,oldY,newY){
        const numlessons = [0,6,5,5,4,5,3,4,4,6,3,3];
        if(newX<290 && newY>=47 && newY<443){
          this.opensidebar(Math.floor((newY-47)/36)+1)
        }
        for(var i=1;i<=11;i++){
          if(((oldX<290 && oldY>=47+36*(i-1) && oldY<47+36*i) || (oldX>=290 && oldX<600 && oldY>=47+36*(i-1) && oldY<47+36*(i-1+numlessons[i]))) && !((newX<290 && newY>=47+36*(i-1) && newY<47+36*i) || (newX>=290 && newX<600 && newY>=47+36*(i-1) && newY<47+36*(i-1+numlessons[i])))){
            this.closesidebar(i)
          }
        }
      },
      opensidebar: function(unit){
        document.getElementById("unit"+unit).style.backgroundColor = "#707070";
        document.getElementById("lessons"+unit).style.visibility = "visible";
        this.opened=unit;
      },
      closesidebar: function(unit){
        document.getElementById("unit"+unit).style.backgroundColor = "#505050";
        document.getElementById("lessons"+unit).style.visibility = "hidden";
      },
      calcheight: function(u,l){
        return 47+36*(u-1)+36*(l-1);
      }
    }
  }
</script>

<style scoped>
  .title {
    position: absolute;
    font-weight:bold;
    font-size: 40px;
    line-height: 1.15;
    width: 35vw;
    left: 25vw;
    top: 10vh;
  }

  .large_logo {
    position: absolute;
    left: 25vw;
    top: 16vh;
    max-width: 35vw;
  }


  .quick_access_button,.practice_test_button,.practice_test_answers_button{
    text-align: left;
    cursor: pointer;
    border: none;
    border-radius: 24px;
    padding: 10px 15px;
    margin: 10px;
    width: 340px;
    position:absolute;
    left: 65vw;
  }
  .quick_access_button{
    background-color: #ddb300;
    top: 150px;
  }
  .quick_access_button:hover{
    background-color: #ffd300;
  }
  .practice_test_button{
    background-color: #0099FF;
    top: 300px;
  }
  .practice_test_button:hover{
    background-color: #40BBFF;
  }
  .practice_test_answers_button{
    background-color: #009933;
    top: 450px;
  }
  .practice_test_answers_button:hover{
    background-color: #00b33c;
  }
  .button_text_outline{
    display:flex;
    width: 210px;
    height: 100px;
    float: left;
    align-items:center;
    margin-left:10px;
    font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    color:#000000;
  }
  .button_icon{
    float: left;
    margin: 5px;
  }
  .sidebar{
    height: 100%;
    width: 290px;
    position: fixed;
    top: 47px;
    left: 0;
    background-color: #505050;
    padding-top: 60px;
    font-size:16px;
    color:#ffffff;
  }
  .sidebar_element{
    font-family: Avenir, Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    width:270px;
    height:16px;
    position:fixed;
    left:0;
    padding:10px;
  }
  .sidebar_element:hover{
    background-color:#707070;
  }
  .sidebar_2{
    height: 36px;
    width: 290px;
    position: fixed;
    left: 290px;
    font-size:16px;
    visibility:hidden;
  }
  .sidebar_2_element{
    font-family: Avenir, Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    width:290px;
    height:16px;
    padding:10px;
    position:fixed;
    left:290px;
    background-color: #505050;
    text-decoration:none;
    color:#ffffff;
  }
  .sidebar_2_element:hover{
    cursor: pointer;
    background-color:#707070;
  }
</style>
