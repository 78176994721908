<template>
  <h1>2.3 Enthalpy of reaction, Beer's Law</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Enthalpy of reaction</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/cEzN33gfgVs" title="Enthalpy of reaction | Thermodynamics | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Beer's Law</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/zuUvQN8KXOk" title="Spectrophotometry and Beer&#39;s Law" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Green chemistry overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/9opyTo7ZIJY" title="What is green Chemistry ? | Sustainable chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>