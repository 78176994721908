<template>
  <h1>7.2 Lewis structures, bond strength, resonance, formal charge</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    
    <span>Drawing Lewis structures (Ketzbook)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/cIuXl7o6mAw" title="Lewis Diagrams Made Easy: How to Draw Lewis Dot Structures" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/qwqXAlvNxsU" title="Lewis Structures Made Easy: Examples and Tricks for Drawing Lewis Dot Diagrams of Molecules" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Bond strength, bond length, bond energy</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/EMCWvehKpd4" title="Bond length and bond energy | AP Chemistry | Khan Academy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Resonance</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/9B5FGPDwX_E" title="Resonance Structures, Basic Introduction - How To Draw The Resonance Hybrid, Chemistry" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Formal charges (Ketzbook)</span>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/Snv655tn0WE" title="Formal Charges Made Easy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>