<template>
  <h1>6.1 Dynamic Equilibrium</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Dynamic equilibrium overview (forward and reverse reaction rates are the same, resulting in no net reaction rate and seemingly no reaction)</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/wlD_ImYQAgQ" title="What Is Dynamic Equilibrium? | Reactions | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>Crash course: intro to equilibrium</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/DP-vWN1yXrY" title="Equilibrium Equations: Crash Course Chemistry #29" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>