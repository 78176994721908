<template>
  <h1>4.1 Intermolecular forces</h1>

  <div class="normal_text" style="margin-top:15px;font-size:20px;">
    <span>Intermolecular forces (IMF) overview</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/9YwdeEDrfPI" title="What Are Intermolecular Forces | Properties of Matter | Chemistry | FuseSchool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <span>More in-depth IMF video</span><br>
    <iframe width="100%" height="400vh" src="https://www.youtube.com/embed/QdwzMPwPA3I" title="Intermolecular Forces - Hydrogen Bonding, Dipole-Dipole, Ion-Dipole, London Dispersion Interactions" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br><br>
    <br>
  </div>
</template>

<style scoped>
  .content_link{
    color:rgb(100,150,255);
  }
</style>